'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import dynamic from 'next/dynamic';
import React from 'react';
import s9 from 'style9';

const Popover = dynamic(() => import('@haaretz/s-popover').then(mod => mod.default));

const spacing = space(3);

const c = s9.create({
  starIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: radius('circle'),
    backgroundColor: color('neutral300'),
    width: space(8),
    aspectRatio: '1',
    position: 'absolute',
    bottom: 0,
    left: 0,

    ...mq({
      from: 's',
      value: {
        backgroundColor: color('sheliIconColor'),
      },
    }),
  },
  popover: {
    maxWidth: '240px',
    paddingTop: space(3),
    paddingBottom: space(3),
    paddingInlineStart: space(3),
    paddingInlineEnd: `calc(${spacing} * 2)`,
    position: 'relative',

    ...mq({
      from: 'xl',
      value: {
        ...typesetter(-2),
      },
    }),
  },
  closeBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: spacing,
    left: space(2),
    '--click-area-size-override': space(4),

    // the arrow of the popover is zIndex 2, so it needs to be higher
    zIndex: 3,
  },
  starIcon: {
    fontSize: space(6),
  },
});

export default function StarPopover() {
  const starIconRef = React.useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenStateChangeHandler = (isOpenFromChild: boolean) => {
    setIsOpen(isOpenFromChild);
  };

  return (
    <>
      <button className={s9(c.starIconWrapper)} ref={starIconRef}>
        <Icon icon="four-point-star" styleExtend={[c.starIcon]} />
      </button>
      <Popover
        refersToRef={starIconRef}
        placement="bottom-end"
        styleExtend={[c.popover]}
        offsetValue={{ crossAxis: space(2), mainAxis: space(1) }}
        onToggle={handleOpenStateChangeHandler}
        isOpen={isOpen}
      >
        <ClickArea
          onClick={() => setIsOpen(false)}
          styleExtend={[c.closeBtn]}
          size="small"
          data-testid="notification-close-btn"
        >
          <Icon icon="close" />
        </ClickArea>
        {fork({
          default: 'האלגוריתמים שלנו בחרו עבורך את הכתבות המעניינות שאולי פספסת',
          hdc: 'Our algorithms have picked for you the most interesting articles you might have missed',
        })}
      </Popover>
    </>
  );
}
