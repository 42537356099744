'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import * as React from 'react';
import s9 from 'style9';

import { logFullPageAdslotExposure } from '../AdSlot/fullPageAdslotUtils';
import { isFixedSize, size2CSS, tranlateFluidSize, createEmptySizesMediaQuery } from '../utils';

import AdCaption from './AdCaption';
import registerViewPorts from './GAMMediaListener';

import type { AdSlotEventHandlers } from '../AdSlot/AdSlot';
import type { AdSlotFragment } from '@haaretz/s-fragments/AdSlot';
import type { InlineStyles } from '@haaretz/s-types';

export interface StaticAdSlotProps
  extends AdSlotEventHandlers,
    Omit<AdSlotFragment, 'inlineStyle' | 'outOfPageFormat' | 'divId'> {
  divId: googletag.enums.OutOfPageFormat | AdSlotFragment['divId'];
  targeting?: { [key: string]: string | string[] };
  isFullPage?: boolean;
}

const ADS_REFRESH_TIMEOUT = 3 * 60000; // three minutes

let observer: IntersectionObserver;
if (typeof window !== 'undefined') {
  observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // refresh ad-slot
          const service = googletag.pubads();
          const slot = service.getSlots().find(s => s.getSlotElementId() === entry.target.id);

          slot && service.refresh([slot]);

          // remove observation
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    }
  );
}

const c = s9.create({
  placeholder: {
    minWidth: 'var(--ad-w, unset)',
    minHeight: 'var(--ad-h, unset)',
    display: 'flex',
    alignItems: 'center',

    ...merge(
      mq({
        from: 's',
        until: 'm',
        value: {
          minWidth: 'var(--ad-sw, unset)',
          minHeight: 'var(--ad-sh, unset)',
        },
      }),
      mq({
        from: 'm',
        until: 'l',
        value: {
          minWidth: 'var(--ad-mw, unset)',
          minHeight: 'var(--ad-mh, unset)',
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          minWidth: 'var(--ad-lw, unset)',
          minHeight: 'var(--ad-lh, unset)',
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          minWidth: 'var(--ad-xlw, unset)',
          minHeight: 'var(--ad-xlh, unset)',
        },
      }),
      mq({
        from: 'xxl',
        value: {
          minWidth: 'var(--ad-xxlw, unset)',
          minHeight: 'var(--ad-xxlh, unset)',
        },
      })
    ),
  },
});

// Mapping between ad-units viewport to MQs
const viewport2Mq = {
  0: '',
  600: 's',
  768: 'm',
  1024: 'l',
  1280: 'xl',
  1920: 'xxl',
} as const;

export default function StaticAdSlot({
  contentId,
  adUnitPath,
  sizes,
  sizeMapping,
  minSizeMapping,
  targeting,
  divId,
  adCaption,
  isFullPage = false,
  slotOnLoad,
  slotImpressionViewable,
}: StaticAdSlotProps) {
  const [isShowCaption, setIsShowCaption] = React.useState(false);
  const slotRef = React.useRef<googletag.Slot>(undefined);
  const slotDivRef = React.useRef<HTMLDivElement>(null);

  // Calculate inine style object
  const style = React.useMemo(() => {
    let css: InlineStyles | undefined;

    if (isFixedSize(sizes)) {
      css = size2CSS(sizes);
    }

    return css;
  }, [sizes]);

  // Event handler for slot-onLoad event
  const slotOnLoadEventHandler = React.useMemo(() => {
    if (!slotOnLoad && !adCaption) {
      return null;
    }

    return (event: googletag.events.SlotOnloadEvent) => {
      if (slotRef.current === event.slot) {
        // Show ad-slot caption when ad-slot is loaded
        if (adCaption) {
          setIsShowCaption(true);
        }

        slotOnLoad && slotOnLoad(slotRef.current);
      }
    };
  }, [adCaption, slotOnLoad]);

  // Event handler for slot-impressionViewable event
  const impressionViewableEventHandler = React.useMemo(() => {
    if (!slotImpressionViewable && !isFullPage) {
      return null;
    }

    return (event: googletag.events.ImpressionViewableEvent) => {
      if (slotRef.current === event.slot) {
        slotImpressionViewable && slotImpressionViewable(slotRef.current);

        if (isFullPage) {
          logFullPageAdslotExposure();
        }
      }
    };
  }, [isFullPage, slotImpressionViewable]);

  React.useEffect(() => {
    if (!sizeMapping?.length || !adCaption) {
      return undefined;
    }

    const mediaQuery = createEmptySizesMediaQuery(sizeMapping);

    if (mediaQuery) {
      const changeHandler = (event: MediaQueryListEvent) => {
        if (event.matches) {
          setIsShowCaption(false);
        } else {
          setIsShowCaption(true);
        }
      };
      mediaQuery.addEventListener('change', changeHandler);

      return () => mediaQuery.removeEventListener('change', changeHandler);
    }

    return undefined;
  }, [sizeMapping, adCaption]);

  // initialize ad-slot
  React.useEffect(() => {
    if (!adUnitPath || !sizes) {
      return undefined;
    }

    let refreshTimeout: number;

    // Keep the ad-slot div reference into variable, for use when component unmounts
    const adDiv = slotDivRef.current;

    const adSizes = sizes.map(tranlateFluidSize);
    let adSizeMapping: googletag.SizeMappingArray | null;
    let slot: googletag.Slot | null | undefined = null;

    if (sizeMapping?.length) {
      // Create listeners for screen-width resize
      registerViewPorts(sizeMapping.map(sm => sm.viewport as googletag.SingleSizeArray));
    }

    // Google AD init
    googletag.cmd.push(() => {
      slot = googletag.defineSlot(adUnitPath, adSizes, `${divId}`)?.addService(googletag.pubads());
      slotRef.current = slot;

      if (!slot) {
        return;
      }

      if (sizeMapping && sizeMapping.length > 0) {
        const adSizeMappingBuilder = googletag.sizeMapping();

        sizeMapping.forEach(mapping => {
          adSizeMappingBuilder.addSize(
            mapping.viewport as googletag.SingleSizeArray,
            mapping.sizes.map(tranlateFluidSize)
          );
        });

        adSizeMapping = adSizeMappingBuilder.build();
        if (adSizeMapping) {
          slot.defineSizeMapping(adSizeMapping);
        }
      }

      if (targeting) {
        slot.updateTargetingFromMap(targeting);
      }

      if (slotOnLoadEventHandler) {
        googletag.pubads().addEventListener('slotOnload', slotOnLoadEventHandler);
      }

      if (impressionViewableEventHandler || observer) {
        googletag.pubads().addEventListener('impressionViewable', evt => {
          if (impressionViewableEventHandler) {
            impressionViewableEventHandler(evt);
          }

          if (slotRef.current === evt.slot && observer && slotDivRef.current !== null) {
            refreshTimeout = window.setTimeout(() => {
              if (slotDivRef.current !== null) {
                observer.observe(slotDivRef.current);
              }
            }, ADS_REFRESH_TIMEOUT);
          }
        });
      }

      googletag.display(slot);
    });

    // Destroy slot on un-mount
    return () => {
      if (slot) {
        if (slotOnLoadEventHandler) {
          googletag.pubads().removeEventListener('slotOnload', slotOnLoadEventHandler);
        }

        if (impressionViewableEventHandler) {
          googletag
            .pubads()
            .removeEventListener('impressionViewable', impressionViewableEventHandler);
        }

        if (observer && adDiv) {
          observer.unobserve(adDiv);
        }

        if (refreshTimeout) {
          window.clearTimeout(refreshTimeout);
        }

        googletag.destroySlots([slot]);
      }
    };
  }, [
    adUnitPath,
    sizes,
    sizeMapping,
    targeting,
    divId,
    adCaption,
    slotOnLoadEventHandler,
    impressionViewableEventHandler,
  ]);

  // adSizes is a map of CSS variables for setting min-height and min-width
  // to the ad DIV, in order to prevent layout shifting
  const [adSizes, hideClasses] = React.useMemo(() => {
    const adSizesMap: Record<string, string> = {};
    const hideClassesSet = new Set(Object.values(viewport2Mq).map(k => `hide_${k}`));

    // if min-size-strategy is 'none', dont need to calculate min-size
    if (!adUnitPath || !minSizeMapping) {
      return [null, null];
    }

    minSizeMapping?.forEach(sm => {
      const mqKey = sm.viewport[0] as keyof typeof viewport2Mq;
      const mqPrefix = viewport2Mq[mqKey];
      const adSize = sm.sizes[0];

      if (typeof mqPrefix === 'undefined') {
        console.warn(`No matching media-query for ad-slot viewport (min-width): ${sm.viewport[0]}.
        current available mapping is:
          ${Object.keys(viewport2Mq)
            .map(vp => {
              const k = vp as unknown as keyof typeof viewport2Mq;
              return `${k} => ${viewport2Mq[k] || 'mobile'}`;
            })
            .join('\n\r')}`);

        return;
      }

      if (adSize) {
        adSizesMap[`--ad-${mqPrefix}w`] = `${adSize[0]}px`;
        adSizesMap[`--ad-${mqPrefix}h`] = `${adSize[1]}px`;
        hideClassesSet.delete(`hide_${mqPrefix}`);
      }
    });

    return [adSizesMap, hideClassesSet];
  }, [minSizeMapping, adUnitPath]);

  if (!adUnitPath) {
    return null;
  }

  return (
    <>
      {adCaption && isShowCaption ? (
        <AdCaption caption={adCaption} isVisible={isShowCaption} />
      ) : null}
      <div
        ref={slotDivRef}
        className={
          adSizes ? `${s9(c.placeholder)} gam-placeholder ${[...hideClasses].join(' ')}` : undefined
        }
        data-testid="static-ad-slot"
        id={`${divId}`}
        style={{ ...(style || {}), ...(adSizes || {}) }}
        suppressHydrationWarning
        data-cid={contentId}
        data-adunit={adUnitPath}
      />
    </>
  );
}
