/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    color: 'var(--clr)',
    marginTop: space(1),
    ...typesetter(-2),
    ...merge({}, mq({ from: 'xxl', value: { ...typesetter(-3) } })),
  },
  isInvalid: {
    '--clr': `var(--err, ${color('secondary900')})`,
  },
  hidden: { display: 'none' },
  defaultVariant: {
    '--clr': color('neutral900'),
  },
  inverseVariant: {
    '--clr': color('neutral200'),
    '--err': color('secondary500'),
  },
});

type Variant = 'default' | 'inverse';
export interface FormfieldDescriptionProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'id'> {
  /** The description or error message to render */
  children?: string;
  /**
   * Sets the basic visual appearance of the input.
   *
   * @defaultValue 'default'
   */
  variant?: Variant;
  /** The ID attribute attached to the element */
  id: string;
  /** Indicate the description is an error message */
  isInvalid?: boolean;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyFormfieldDescription(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <FormfieldDescription
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <FormfieldDescription styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export default function FormfieldDescription({
  ref,
  children,
  id,
  inlineStyle,
  isInvalid,
  styleExtend = [],
  variant = 'default',
  ...attrs
}: FormfieldDescriptionProps & React.RefAttributes<HTMLDivElement>) {
  const variantClass: `${FormfieldDescriptionProps['variant']}Variant` = `${variant}Variant`;

  return (
    <div
      ref={ref}
      aria-live={isInvalid ? 'assertive' : 'off'}
      role={isInvalid ? 'alert' : undefined}
      {...attrs}
      id={id}
      className={s9(c.base, c[variantClass], isInvalid && c.isInvalid, ...styleExtend)}
      style={inlineStyle}
    >
      {children}
    </div>
  );
}
