import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import HtzImage from '@haaretz/s-htz-image';
import React from 'react';
import s9 from 'style9';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';

const c = s9.create({
  image: {
    ...merge(
      mq({ from: 's', until: 'l', value: { width: '100%' } }),
      mq({ from: 'l', until: 'xl', value: { width: space(256) } }),
      mq({ from: 'xl', until: 'xxl', value: { width: space(228) } }),
      mq({ from: 'xxl', value: { width: space(256) } })
    ),
  },

  caption: {
    color: color('textLightAllModes'),
    fontWeight: '700',
    display: 'none',
    paddingTop: space(2),
    ...merge(
      mq({
        from: 's',
        value: {
          paddingInlineEnd: space(7),
          paddingTop: space(1),
          display: 'block',
          maxWidth: '100%',
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          paddingInlineStart: 'var(--LayoutContainerGutter)',
          minHeight: space(13),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          paddingTop: space(2),
          paddingInlineStart: space(5),
          minHeight: space(16),
          ...typesetter(1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          minHeight: space(14),
          paddingBottom: space(3),
          ...typesetter(-1, { lines: 5 }),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          minHeight: space(15),
          paddingBottom: space(2),
          ...typesetter(-1, { lines: 6 }),
        },
      })
    ),
  },
  position: {
    color: color('slimLightBlueInAllModes'),
    marginInlineEnd: space(1),
  },
  credit: {
    fontWeight: '300',
    marginInlineStart: space(1),
  },
});

const widths: number[] = [430, 645, 562, 1536];
const sizes: React.ComponentProps<typeof HtzImage>['sizes'] = [
  { from: 'l', size: '1536px' },
  { from: 's', size: '900px' },
];

export default function GalleryImage({
  image,
  index,
  length,
  children,
}: {
  image: ImageFragment;
  index: number;
  length: number;
  children?: React.ReactNode;
}) {
  return (
    <div
      style={{
        display: 'inline-block',
        width: 'fit-content',
      }}
    >
      <HtzImage
        caption={image.caption}
        credit={image.credit}
        type={image.type}
        photographer={image.photographer}
        contentId={image.contentId || ''}
        url={image.url}
        alt={image.alt || ''}
        imgData={image.files[0]}
        aspect="headline"
        sizes={sizes}
        widths={widths}
        styleExtend={[c.image]}
        priority
      />
      {children}

      <div className={s9(c.caption)}>
        <p>
          {length ? (
            <span className={s9(c.position)}>
              {index + 1}/{length}
            </span>
          ) : null}{' '}
          <span>{image.caption}</span> <span className={s9(c.credit)}>{image.credit}</span>
        </p>
      </div>
    </div>
  );
}
