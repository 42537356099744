'use client';

import usePlatform from '@haaretz/s-atoms/platform';
import useOnce from '@haaretz/s-use-once';
import React from 'react';

type NativeAppUserAgent = 'android' | 'ios';
type NativeAppUserAgentProviderType = { fallback: React.ReactNode } | undefined;

const NativeAppUserAgentContext = React.createContext<NativeAppUserAgent | undefined>(undefined);

/**
 * ATTENTION: For using in the native apps only.
 */

export default function useAppUserAgent() {
  const platform = usePlatform();
  const appUserAgent = React.use(NativeAppUserAgentContext);

  if (platform !== 'app') {
    return null;
  }

  if (appUserAgent === undefined) {
    throw new Error('useAppUserAgent must be used within a NativeAppUserAgentContext');
  }

  return appUserAgent;
}

function NativeAppUserAgentProvider({
  children,
  fallback,
}: Readonly<React.PropsWithChildren<NativeAppUserAgentProviderType>>) {
  const [userAgent, setUserAgent] = React.useState<NativeAppUserAgent | undefined>(undefined);

  useOnce(() => {
    setUserAgent(/iPad|iPhone|iPod/.test(navigator.userAgent) ? 'ios' : 'android');
  }, true);

  if (userAgent === undefined) {
    /**
     * HACK: because on server side we don't have userAgent because of CDN caching,
     * so we render fallback
     * Same on first render on client side, because userAgent is not defined on first render on client side.
     */

    return fallback;
  }

  return <NativeAppUserAgentContext value={userAgent}>{children}</NativeAppUserAgentContext>;
}

export function NativeAppUserAgentWrapper({
  children,
  fallback,
}: Readonly<React.PropsWithChildren<NativeAppUserAgentProviderType>>) {
  const platform = usePlatform();

  if (platform !== 'app') {
    return children;
  }

  return <NativeAppUserAgentProvider fallback={fallback}>{children}</NativeAppUserAgentProvider>;
}
