'use client';
import { useClientTeasersOnPageAtom } from '@haaretz/s-atoms/clientTeasersOnPage';
import useList, { useListActions } from '@haaretz/s-use-list';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import React from 'react';

import lazyListLoader from '../../server-actions/lazyListLoader';

import type { MandatoryListProps, WithClientListProps } from '@haaretz/s-data-structure-types';
import type { ListFragment } from '@haaretz/s-fragments/List';

export interface LazyProps
  extends WithClientListProps<MandatoryListProps>,
    Pick<ListFragment, 'renderedArticleIds'> {}

export default function LazyListClient({
  children: placeholder,
  ...props
}: React.PropsWithChildren<LazyProps>) {
  const { inView, obeservedElement } = useList();
  const { setIsShowing } = useListActions();
  const [list, setList] = React.useState<React.ReactNode>(undefined);
  const isLoaded = useLoadEvent();
  const [{ getExcludeString }, { addClientTeasers }] = useClientTeasersOnPageAtom();

  const { pageContentId } = props;
  const isLazyList = props.kind === 'lazy';
  const [, startLazyTransition] = React.useTransition();

  const shouldLoading = isLazyList && isLoaded && inView && !list;

  const exclude = getExcludeString(props.renderedArticleIds);

  const handleLoadLazyList = React.useCallback(
    function handleLoadMore() {
      startLazyTransition(async () => {
        try {
          const result = await lazyListLoader({
            listStyle: props.listStyle,
            postCacheParams: 'sa-list-lazy',
            variables: {
              mainContentId: pageContentId,
              model: { id: props.contentId },
              exclude,
              page: 1,
            },
          });

          /* istanbul ignore next */
          if (result?.result) {
            // eslint-disable-next-line no-console
            console.table({
              title: 'LazyList',
              style: props.listStyle,
              contentId: props.contentId,
              result: result.result,
              teaserIds: result.itemIds.toString(),
            });
          }

          if (result?.html) {
            setList(result.html);
            addClientTeasers(result.itemIds);
          } else {
            setIsShowing(false);
            console.error(new Error('Personal list failed to load'));
          }
        } catch (error) {
          /* istanbul ignore next */
          setIsShowing(false);

          /* istanbul ignore next */
          console.error(error);
        }
      });
    },
    [props.listStyle, props.contentId, exclude, pageContentId, addClientTeasers, setIsShowing]
  );

  React.useEffect(() => {
    if (shouldLoading) {
      handleLoadLazyList();
    }
  }, [handleLoadLazyList, shouldLoading]);

  if (list) {
    return list;
  }

  return (
    <>
      {obeservedElement}
      {placeholder}
    </>
  );
}
