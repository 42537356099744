import browserStorageGetItem from '@haaretz/s-browser-storage-utils/getItem';
import browserStorageSetItem from '@haaretz/s-browser-storage-utils/setItem';

const FULL_PAGE_ADSLOT_IDENTIFIERS = ['full_interstitial.Mobile'];
const FULL_PAGE_ADSLOT_EXPOSURE_TIME_KEY = 'GAM_fpbet';
const FULL_PAGE_ADSLOT_INTERVAL_MILLIS = 2 * 60 * 1000; // A full-page banner can appear once in 2 minutes

export function isFullPageAdslotPredicate(adUnitPath: string) {
  return FULL_PAGE_ADSLOT_IDENTIFIERS.some(identifier => adUnitPath.includes(identifier));
}

export function canDisplayFullPageAdslot() {
  const storageValue = browserStorageGetItem({
    key: FULL_PAGE_ADSLOT_EXPOSURE_TIME_KEY,
    storage: 'local',
  });

  let canDisplay = true;

  if (storageValue) {
    const lastExposureTime = parseInt(storageValue) || 0;
    const now = Date.now();
    const intervalInMillis = FULL_PAGE_ADSLOT_INTERVAL_MILLIS;

    canDisplay = now - lastExposureTime >= intervalInMillis;
  }

  return canDisplay;
}

export function logFullPageAdslotExposure() {
  browserStorageSetItem({
    key: FULL_PAGE_ADSLOT_EXPOSURE_TIME_KEY,
    value: Date.now(),
    storage: 'local',
  });
}
