'use client';

import fork from '@haaretz/l-fork.macro';
import { useDescendant } from '@haaretz/s-ui-providers/DescendantsProvider';
import * as React from 'react';
import s9 from 'style9';

import { makeId, TabsDescendantContext } from '../Tabs';

import { TabsContext, TabsUpdaterContext } from './providers/TabsProvider';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';
import type { Descendant } from '@haaretz/s-ui-providers/DescendantsProvider';

export interface TabProps extends React.RefAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean;
  styleExtend?: StyleExtend;
  activeTabStyleExtend?: StyleExtend;
  inlineStyle?: InlineStyles;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export default function Tab({
  ref,
  children,
  disabled,
  inlineStyle,
  onClick,
  onMouseEnter,
  onMouseLeave,
  styleExtend = [],
  activeTabStyleExtend = [],
}: TabProps) {
  const { selectedIndex, rootId, activeTabRef } = React.use(TabsContext);
  const { onSelectTab, setActiveTabRef } = React.use(TabsUpdaterContext);
  const { descendants } = React.use(TabsDescendantContext);
  const tabRef = React.useRef<HTMLButtonElement>(null);
  React.useImperativeHandle(ref, () => tabRef.current as HTMLButtonElement, []);
  const index = useDescendant(tabRef.current, TabsDescendantContext);
  const tabPanelId = makeId(rootId, 'tabpanel', index);
  const isSelected = index === selectedIndex;
  function onSelect() {
    onClick && onClick();
    onSelectTab(index);
  }

  function handleMouseEnter() {
    if (tabRef.current) {
      onMouseEnter && onMouseEnter();
    }
  }
  function handleMouseLeave() {
    if (activeTabRef) {
      onMouseLeave && onMouseLeave();
    }
  }

  function onKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const offset =
        event.key === fork<'ArrowLeft' | 'ArrowRight'>({ default: 'ArrowLeft', hdc: 'ArrowRight' })
          ? 1
          : -1;
      const nextIndexToFocus = calculateNextIndexToFocus(descendants, index, offset);

      descendants[nextIndexToFocus]?.element?.focus();
      onSelectTab(nextIndexToFocus);
    }
  }

  React.useEffect(() => {
    const currentTabRef = tabRef.current;

    if (isSelected && currentTabRef) {
      setActiveTabRef(currentTabRef);
    }
  }, [isSelected, activeTabRef, setActiveTabRef, index]);
  const activeStyleExtend = isSelected ? activeTabStyleExtend : [];
  return (
    <button
      className={s9(...styleExtend, ...activeStyleExtend)}
      style={inlineStyle}
      ref={tabRef}
      disabled={disabled}
      aria-controls={tabPanelId}
      aria-selected={isSelected}
      aria-disabled={disabled}
      role="tab"
      tabIndex={disabled || !isSelected ? -1 : undefined}
      onClick={onSelect}
      onKeyDown={onKeyDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </button>
  );
}

function calculateNextIndexToFocus(
  descendants: Array<Descendant<HTMLButtonElement>>,
  currentIndex: number,
  offset: number
) {
  let index =
    (fork({
      default: currentIndex + offset,
      hdc: currentIndex - offset + descendants.length,
    }) +
      descendants.length) %
    descendants.length;

  while (descendants[index]?.element?.disabled) {
    index =
      (fork({
        default: index + offset,
        hdc: index - offset + descendants.length,
      }) +
        descendants.length) %
      descendants.length;
  }

  return index;
}
