'use client';
import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import ClickArea from '@haaretz/s-click-area';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import isLiveBlogBreakingNewsElement from '@haaretz/s-type-predicates/isLiveBlogBreakingNews';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import LiveBlogBreakingNewsItem from './LiveBlogBreakingNewsItem';
import { breakingNewTitle } from './utils';

import type { HeaderNewsListFragment } from '@haaretz/s-fragments/HeaderNewsList';
import type { LiveBlogBreakingNewsFragment } from '@haaretz/s-fragments/LiveBlogBreakingNews';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    display: 'grid',
    columnGap: space(1),
    alignItems: 'center',
    rowGap: space(1),
    gridAutoColumns: 'auto 1fr auto',
    ...border({
      color: color('neutral300'),
      spacing: 1,
      style: 'solid',
      width: '1px',
      side: 'bottom',
    }),
    ...merge(
      mq({ until: 'l', value: { display: 'none' } }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icons: { fontSize: space(4) },
  buttonGroup: {
    display: 'flex',
    gridColumnStart: 3,
    gridColumnEnd: 4,
    columnGap: space(2),
    color: color('primary1000'),
  },
  prev: {
    transform: 'rotate(180deg)',
  },
  breaking: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    '::after': {
      content: '" • "',
    },
    color: color('primary1000'),
    fontWeight: 700,
    ...typesetter(0),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
});

export interface LiveBlogBreakingNewsProps {
  inlineStyle?: InlineStyles;
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
  data: LiveBlogBreakingNewsFragment | HeaderNewsListFragment;
}

export default function LiveBlogBreakingNewsClient({
  inlineStyle,
  styleExtend = [],
  data,
  children,
}: LiveBlogBreakingNewsProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isLoop, setIsLoop] = React.useState(true);
  const { items } = data;

  const url = isLiveBlogBreakingNewsElement(data) ? data.url : '/misc/breaking-news';

  React.useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined;
    if (isLoop && items && items.length) {
      intervalId = setInterval(() => {
        setCurrentIndex(index => (index + 1) % items.length);
      }, 4000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [isLoop, items]);

  if (!items || items.length === 0) {
    return null;
  }

  const onClickNext = () => {
    setCurrentIndex(prev => (prev + 1) % items.length);
    setIsLoop(false);
  };
  const onClickPrev = () => {
    setCurrentIndex(prev => (prev - 1 + items.length) % items.length);
    setIsLoop(false);
  };
  const onPause = () => {
    setIsLoop(p => !p);
  };

  return (
    <>
      {children}
      <div className={s9(c.base, ...styleExtend)} style={inlineStyle}>
        {items.map((item, index) => (
          <LiveBlogBreakingNewsItem key={index} item={item} isCurrent={index === currentIndex} />
        ))}
        <HtzLink className={s9(c.breaking)} href={url ?? ''}>
          {breakingNewTitle}
        </HtzLink>
        <div className={s9(c.buttonGroup)}>
          <ClickArea size="extraSmall" disableRipple onClick={onClickNext} styleExtend={[c.center]}>
            <Icon icon="chevron" styleExtend={[c.icons]} />
            <VisuallyHidden>{fork({ default: 'המאמר הבא', hdc: 'Next article' })}</VisuallyHidden>
          </ClickArea>
          <ClickArea size="extraSmall" disableRipple onClick={onPause} styleExtend={[c.center]}>
            <Icon icon={isLoop ? 'pause' : 'play'} styleExtend={[c.icons]} />
            <VisuallyHidden>
              {fork({ default: isLoop ? 'הפעל' : 'עצור', hdc: isLoop ? 'play' : 'pause' })}
            </VisuallyHidden>
          </ClickArea>
          <ClickArea size="extraSmall" disableRipple onClick={onClickPrev} styleExtend={[c.center]}>
            <Icon icon="chevron" styleExtend={[c.prev, c.icons]} />
            <VisuallyHidden>
              {fork({ default: 'מאמר קודם', hdc: 'Previous article' })}
            </VisuallyHidden>
          </ClickArea>
        </div>
      </div>
    </>
  );
}
