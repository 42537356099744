'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { FINANCE_BASE_PATH } from '@haaretz/s-consts';
import HtzLink from '@haaretz/s-htz-link';
import { useIndexAssetsTableQuery } from '@haaretz/s-queries/AssetsListClient';
import React from 'react';
import s9 from 'style9';

import { StocksTabsContext, StocksTabsContextUpdater } from './StocksTabsProvider';
import { ChangePercentageTd, Td, Thead, Tr } from './TablePrimitives';

import type { AssetFragment } from '@haaretz/s-fragments/Asset';

const c = s9.create({
  container: {
    gridColumnEnd: 'span 12',
    gridColumnStart: '1',
    overflow: 'hidden',
    ...merge(
      mq({
        from: 's',
        value: {
          gridColumnEnd: 'span 4',
        },
      }),
      mq({
        from: 'l',
        value: {
          gridColumnEnd: 'span 2',
        },
      })
    ),
  },
  table: {
    width: '100%',
    backgroundColor: color('neutral100'),
    marginTop: space(3),
    ...typesetter(-1),
  },
  tbody: {
    fontWeight: '700',
  },
  tdWrapper: {
    backgroundColor: 'transparent',
    fontWeight: '700',
    textAlign: 'start',
  },
  truncatedTd: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  },
  hiddenFromS: {
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
  hiddenUntilS: {
    ...merge(mq({ until: 's', value: { display: 'none' } })),
  },
});

export type ColumnType = 'name' | 'value' | 'changePercentage';

interface GraphTableProps {
  assetIds: string[];
}

function GraphTable({ assetIds }: GraphTableProps) {
  const { data } = useIndexAssetsTableQuery({ assetIds });
  const selectedGraphAsset = React.use(StocksTabsContext);
  const setSelectedGraphAsset = React.use(StocksTabsContextUpdater);

  React.useEffect(() => {
    if (!selectedGraphAsset && data?.assets?.length) setSelectedGraphAsset(data?.assets[0].id);
  }, [data?.assets, selectedGraphAsset, setSelectedGraphAsset]);

  return data?.assets ? (
    <div className={s9(c.container)}>
      <table className={s9(c.table)}>
        <Thead tableType="graphs" />
        <tbody className={s9(c.tbody)}>
          {data?.assets?.map((asset, index) => (
            <Tr
              key={asset.id}
              isLast={data?.assets?.length === index + 1}
              isActive={selectedGraphAsset === asset.id}
            >
              <GraphTd asset={asset} column="name" />
              <GraphTd asset={asset} column="value" />
              <GraphTd asset={asset} column="changePercentage" />
            </Tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
}

export default function GraphTableWrapper(props: GraphTableProps) {
  return (
    <React.Suspense fallback={null}>
      <GraphTable {...props} />
    </React.Suspense>
  );
}

function GraphTd({ column, asset }: { column: ColumnType; asset: AssetFragment }) {
  const href = `${FINANCE_BASE_PATH}/${asset.type}/${asset.id}`;
  const setGraphAssetId = React.use(StocksTabsContextUpdater);

  return (
    <Td column={column}>
      <HtzLink
        href={href}
        className={s9(c.tdWrapper, column === 'name' && c.truncatedTd, c.hiddenFromS)}
      >
        {column === 'changePercentage' ? (
          <ChangePercentageTd change={asset.changePercentage} />
        ) : (
          asset[column]
        )}
      </HtzLink>

      <button
        onClick={() => setGraphAssetId(asset.id)}
        className={s9(c.tdWrapper, column === 'name' && c.truncatedTd, c.hiddenUntilS)}
      >
        {column === 'changePercentage' ? (
          <ChangePercentageTd change={asset.changePercentage} />
        ) : (
          asset[column]
        )}
      </button>
    </Td>
  );
}
