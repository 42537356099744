'use client';
import space from '@haaretz/l-space.macro';
import { useDescendant } from '@haaretz/s-ui-providers/DescendantsProvider';
import * as React from 'react';
import s9 from 'style9';

import { makeId } from '../Tabs';

import { TabsContext } from './providers/TabsProvider';
import { TabPanelsDescendantContext } from './TabPanels';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';
import type { JSX } from 'react';

const c = s9.create({
  tabpanel: {
    paddingTop: space(1),
  },
});

type TabPanelProps = {
  children: React.ReactNode;
  styleExtend?: StyleExtend;
  inlineStyle?: InlineStyles;
} & Omit<
  JSX.IntrinsicElements['div'],
  'id' | 'aria-labelledby' | 'role' | 'hidden' | 'style' | 'className'
>;

function TabPanel({ children, styleExtend = [], inlineStyle, ...rest }: TabPanelProps) {
  const tabPanelRef = React.useRef<HTMLDivElement>(null);
  const { rootId, selectedIndex } = React.use(TabsContext);

  const index = useDescendant(tabPanelRef.current, TabPanelsDescendantContext);
  const tabId = makeId(rootId, 'tab', index);
  const tabpanelId = makeId(rootId, 'tabpanel', index);

  return (
    <div
      {...rest}
      id={tabpanelId}
      aria-labelledby={tabId}
      ref={tabPanelRef}
      role="tabpanel"
      hidden={index !== selectedIndex}
      className={s9(c.tabpanel, ...styleExtend)}
      style={inlineStyle}
    >
      {children}
    </div>
  );
}

export default TabPanel;
