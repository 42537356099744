'use client';
import { useClientTeasersOnPageAtom } from '@haaretz/s-atoms/clientTeasersOnPage';
import React from 'react';

import listLoader from '../../server-actions/listLoader';

import type {
  MandatoryListProps,
  WithClientListProps,
  PaginatorProps,
} from '@haaretz/s-data-structure-types';
import type { ListFragment } from '@haaretz/s-fragments/List';

export interface PaginationProps
  extends WithClientListProps<MandatoryListProps>,
    Pick<ListFragment, 'renderedArticleIds'> {
  ListPaginator: React.ComponentType<PaginatorProps>;
}

export default function PaginationListClient({
  children: placeholder,
  ListPaginator,
  ...props
}: React.PropsWithChildren<PaginationProps>) {
  const [teasers, setTeasers] = React.useState<React.ReactNode>(undefined);
  const [teasersToDisplay, setTeasersToDisplay] = React.useState<React.ReactNode[]>([]);
  const [{ getExcludeString }, { addClientTeasers }] = useClientTeasersOnPageAtom();
  const [page, setPage] = React.useState(1);
  const [hasMoreTeasers, setHasMoreTeasers] = React.useState(false);

  const { pageContentId } = props;
  const [isPending, startTransition] = React.useTransition();

  const exclude = getExcludeString(props.renderedArticleIds);

  const handleLoadMore = React.useCallback(
    function handleLoadMore() {
      startTransition(async () => {
        try {
          const result = await listLoader({
            listStyle: props.listStyle,
            postCacheParams: 'sa-list-load-more',
            variables: {
              mainContentId: pageContentId,
              model: { id: props.contentId },
              exclude,
              page,
            },
          });

          /* istanbul ignore next */
          if (result?.result) {
            // eslint-disable-next-line no-console
            console.table({
              title: 'LoadMoreTeasers',
              style: props.listStyle,
              contentId: props.contentId,
              result: result.result,
              teaserIds: result.itemIds.toString(),
            });
          }

          if (result?.html) {
            setTeasers(result.html);
            addClientTeasers(result.itemIds);
            setPage(prev => prev + 1);
            setHasMoreTeasers(true);
          } else {
            setHasMoreTeasers(false);
          }
        } catch (error) {
          /* istanbul ignore next */
          console.error(error);
          /* istanbul ignore next */
          setHasMoreTeasers(false);
        }
      });
    },
    [addClientTeasers, exclude, page, pageContentId, props.contentId, props.listStyle]
  );

  const displayMoreItems = () => {
    handleLoadMore();
    setTeasersToDisplay(prevItems => [...prevItems, teasers]);
  };

  React.useEffect(() => {
    handleLoadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListPaginator
      displayMoreItems={displayMoreItems}
      isPending={isPending}
      hasMoreTeasers={hasMoreTeasers}
    >
      {teasersToDisplay}
    </ListPaginator>
  );
}
